import session from '../api/session';
import {
    DEFAULT_DATA,
    SET_TOKEN,
    SET_ROUTE_SHOWN,
    SET_SIDEBAR_OPENED,
    SET_PICKER,
    WALL_SETTINGS,
    SETTINGS_WALL,
    SET_NEW_COMMENT,
    SET_EDIT_COMMENT
} from './types';

const state = {
    isRouteShown: false,
    onWizard: false,
    domain: {},
    current_user: {},
    time: {
        date: '',
        time_zone: ''
    },
    module: {},
    limits: {
        MIN_CHARS: 4
    },
    MONTHS_NAMES: {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь'
    },
    newCourseId: null,
    wallSettings: {
        userPost: {
            label: 'Посты пользователей',
            value: true
        },

        advertPost: {
            label: 'Объявления',
            value: true
        },

        newUsers: {
            label: 'Новые пользователи',
            value: true
        },

        newMaterials: {
            label: 'Новые материалы',
            value: true
        }
    },
    current_comment: {
        new: {},
        edit: {}
    }
};

const mutations = {
    [DEFAULT_DATA](state, data) {
        state.onWizard = data.onWizard;
        state.domain = data.domain;
        state.module = data.module;
        state.time = data.time;
        state.current_user = data.user;
        state.knowledge_base = data.knowledge_base;
        state.isBlocked = data.isBlocked || false;

        // wallSettings
        state.wallSettings.userPost.value = data.wall ? data.wall.post : true;
        state.wallSettings.advertPost.value = data.wall ? data.wall.advert : true;
        state.wallSettings.newUsers.value = data.wall ? data.wall.user : true;
        state.wallSettings.newMaterials.value = data.wall ? data.wall.material : true;
    },

    [SET_ROUTE_SHOWN](state, data) {
        state.isRouteShown = data;
    },

    [WALL_SETTINGS](state, data) {
        const key = data.key;
        const value = data.value;

        state.wallSettings[key].value = value;
    },

    [SET_NEW_COMMENT](state, data) {
        state.current_comment.new = data;
    },

    [SET_EDIT_COMMENT](state, data) {
        state.current_comment.edit = data;
    },

    setNewCourseId(state, data) {
        state.newCourseId = data;
    }
};

const actions = {
    initialize({ commit }) {
        return session.get('/api/v1/default-data/')
            .then(({ data }) => commit(DEFAULT_DATA, data));
    },

    setRouteShown({ commit }, data) {
        commit(SET_ROUTE_SHOWN, data);
    },

    changeWallSettings({ commit }, data) {
        commit(WALL_SETTINGS, data);
    },

    setNewComment({ commit }, data) {
        commit(SET_NEW_COMMENT, data);
    },

    setEditComment({ commit }, data) {
        commit(SET_EDIT_COMMENT, data);
    },

    setDeleteComment({ commit }, data) {
        commit(SET_DELETE_COMMENT, data);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters: {
        current_user: state => state.current_user,
        current_comment: state => state.current_comment,
        module: state => state.module,
        domain: state => state.domain,
        onWizard: state => state.onWizard,
        isRouteShown: state => state.isRouteShown,
        isBlocked: state => state.isBlocked || false,
        isAdmin: state => state.current_user.is_admin,
        isEditorCourse: state => state.current_user.is_editor_course,
        isEditorMaterial: state => state.current_user.is_editor_material,
        knowledgeBaseId: state => state.knowledge_base,
        newCourseId: state => state.newCourseId
    },
    actions
};
